
import { defineComponent, inject, onMounted, ref, computed } from "vue";
import { useRouter } from "vue-router";
import { useToast } from "vue-toastification";

// services
import projectService from "@/services/project.service";
import bundleService from "@/services/bundle.service";

// components
import AssessmentList from "@/components/molecules/AssessmentList.molecules.vue";

// interfaces
import { IAssessment } from "@/interfaces/assessment";
import { AxiosError } from "axios";
import InputLabel from "@/components/atoms/InputLabel.vue";

export default defineComponent({
  components: {
    AssessmentList,
    InputLabel,
  },
  setup() {
    const router = useRouter();
    const toast = useToast();
    const setPageTitle = inject("setPageTitle") as CallableFunction;
    onMounted(() => setPageTitle("Buat Proyek"));

    // get assessments list
    let assessments = ref<IAssessment[]>([]);
    onMounted(async () => {
      const { data } = await bundleService.getBundles();
      assessments.value = data;
    });

    // select assessment
    const selectedAssessment = ref<number[]>([]);
    const setSelectedAssessment = (val: number[]) => {
      selectedAssessment.value = val;
    };

    // handle form
    const projectName = ref<string>("");
    const startedAt = ref<string>("");
    const endedAt = ref<string>("");

    const createProject = async () => {
      try {
        const req = {
          name: projectName.value,
          started_at: startedAt.value,
          ended_at: endedAt.value,
          bundle_id: selectedAssessment.value,
        };

        const { data } = await projectService.createProject(req);
        localStorage.setItem("showSuccessProjectModal", "true");
        router.push(`/admin/project/${data.id}`);
      } catch (e) {
        console.error(e);
        let errMsg = (e as AxiosError).response?.data?.error || "Ada kesalahan";
        toast.error(errMsg);
      }
    };

    const canSubmit = computed(
      () =>
        projectName.value &&
        startedAt.value &&
        endedAt.value &&
        selectedAssessment.value.length
    );

    return {
      assessments,
      projectName,
      startedAt,
      endedAt,
      createProject,
      selectedAssessment,
      setSelectedAssessment,
      canSubmit,
    };
  },
});
