import httpClient from "./http-client";
import authHeader from "./auth-header";
import { AxiosRequestHeaders } from "axios";

const getBundles = async (size = 10): Promise<any> => {
  const params = {
    size,
  };

  const { data } = await httpClient.get("/bundle", {
    params,
    headers: authHeader() as AxiosRequestHeaders,
  });

  return data;
};

export default {
  getBundles,
};
